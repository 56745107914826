import { render, staticRenderFns } from "./Breakdowns.vue?vue&type=template&id=20d7ee9c&scoped=true&"
import script from "./Breakdowns.vue?vue&type=script&lang=js&"
export * from "./Breakdowns.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20d7ee9c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20d7ee9c')) {
      api.createRecord('20d7ee9c', component.options)
    } else {
      api.reload('20d7ee9c', component.options)
    }
    module.hot.accept("./Breakdowns.vue?vue&type=template&id=20d7ee9c&scoped=true&", function () {
      api.rerender('20d7ee9c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/tables/Breakdowns.vue"
export default component.exports